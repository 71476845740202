var Loader = {
    loader: null,
    MAX_LOADER_VALUE: 100,

    init: function() {
        var DEFAULT_LOADER_VALUE = 1;
        var DEFAULT_TITLE = "Please wait...";

        Loader.changeTitle(DEFAULT_TITLE);
        Loader.changeLoaderValue(DEFAULT_LOADER_VALUE);
    },

    show: function(option) {
        option = option || {};
        UIkit.modal("#riddell-loader-new", option).show();
    },

    hide: function(option) {
        option = option || {};
        UIkit.modal("#riddell-loader-new", option).hide();
    },

    changeTitle: function(title) {
        $('#riddell-loader-new h6').text(title);
    },

    changeLoaderValue: function(val) {
        $("#progressbar").val(val);
        $('#riddell-loader-new h3 span').text(val + "%");
    },

    getCurrentLoaderValue: function() {
        return $('#progressbar').val();
    },

    loading: function(from_ratio, to_ratio, callback) {
        if (Loader.loader !== null) {
            clearInterval(Loader.loader);
            Loader.loader = null;
        }

        if (from_ratio <= to_ratio) {
            var range = parseInt(Loader.MAX_LOADER_VALUE / to_ratio);
            var from = range * (from_ratio-1);
            var to = range * from_ratio;

            Loader.changeLoaderValue(from++);
            Loader.loader = setInterval(function() {
                if (from <= to) {
                    Loader.changeLoaderValue(from++);
                } else {
                    clearInterval(Loader.loader);

                    Loader.changeLoaderValue(to);
                    setTimeout(function() {
                        if (typeof callback === "function") {
                            callback();
                        }
                    }, 500);
                }
            }, 700);
        } else {
            var loader_value = Loader.getCurrentLoaderValue();
            var INCREMENT = parseInt((Loader.MAX_LOADER_VALUE - loader_value) / 5);

            Loader.changeLoaderValue(loader_value); loader_value += INCREMENT;
            Loader.loader = setInterval(function() {
                if (loader_value <= Loader.MAX_LOADER_VALUE) {
                    Loader.changeLoaderValue(loader_value); loader_value += INCREMENT;
                } else {
                    clearInterval(Loader.loader);

                    Loader.changeLoaderValue(Loader.MAX_LOADER_VALUE);
                    setTimeout(function() {
                        Loader.loader = null;
                        if (typeof callback === "function") {
                            callback();
                        }
                    }, 500);
                }
            }, 100);
        }
    },

    endloading: function(callback, option) {
        option = option || {};

        Loader.loading(0, -1, function() {
            Loader.hide(option);
            if (typeof callback === "function") {
                callback();
            }
        });
    },

    completeloading: function(callback) {
        Loader.loading(0, -1, function() {
            Loader.changeTitle("Please wait...");
            if (typeof callback === "function") {
                callback();
            }
        });
    },

    pageloading: function() {
        $('#riddell-loader-new').addClass("uk-text-center");
        $('#riddell-loader-new h3').html('The page is loading. Please wait. <div uk-spinner></div>');
        $('#riddell-loader-new h6').remove();
        $('#progressbar').remove();

        Loader.show();
    }
};

Loader.styleName = {
    open: function(option) {
        Loader.changeTitle("Opening Style Name...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    saving: function(option) {
        Loader.changeTitle("Saving Style Name...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};


Loader.styleBaseCut = {
    open: function(option) {
        Loader.changeTitle("Opening Style Base Cut...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    saving: function(option) {
        Loader.changeTitle("Saving Style Base Cut...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};

Loader.styleBaseCutReversible = {
    openSide1: function(option) {
        Loader.changeTitle("Opening Style Base Cut Side 1...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    openSide2: function(option) {
        Loader.changeTitle("Opening Style Base Cut Side 2...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    saving: function(option) {
        Loader.changeTitle("Saving Style Base Cut...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};

Loader.customizer = {
    open: function() {
        $('#riddell-loader-new').addClass("uk-flex uk-open");
        Loader.changeLoaderValue(80);
    },

    close: function() {
        Loader.changeTitle("Rendering Awesomeness...");
        $('#riddell-loader-new').removeClass("uk-flex uk-open");
    },

    saving: function(option) {
        Loader.changeTitle("Saving Design...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    savingReversibleSide1: function(option) {
        Loader.changeTitle("Saving Reversible Side 1 ...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    savingReversibleSide2: function(option) {
        Loader.changeTitle("Saving Reversible Side 2 ...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },
};

Loader.roster = {
    open: function(option) {
        Loader.changeTitle("Opening Roster...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    saving: function(option) {
        Loader.changeTitle("Saving Roster...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    endloading: function(callback, option) {
        option = option || {};

        Loader.loading(0, -1, function() {
            // Loader.hide(option);
            if (typeof callback === "function") {
                callback();
            }
        });
    }

};

Loader.appSize = {
    open: function(option) {
        Loader.changeTitle("Opening Application Size...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    openSideB: function(option) {
        Loader.changeTitle("Opening Application Size Side B...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    saving: function(option) {
        Loader.changeTitle("Saving Application Size...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    uploading: function(option) {
        Loader.changeTitle("Uploading File...")
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};

Loader.item = {
    changing: function(option) {
        Loader.changeTitle("Changing Item...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    duplicating: function(option) {
        Loader.changeTitle("Duplicating Item...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    deleting: function(option) {
        Loader.changeTitle("Deleting Item...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    unlockingApproved: function(option) {
        Loader.changeTitle("Unlocking Approved Item...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    unlockingPending: function(option) {
        Loader.changeTitle("Unlocking Pending Item...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    fixChanges: function(option) {
        Loader.changeTitle("Fix Changes...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    approving: function(option) {
        Loader.changeTitle("Approving Item...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },
};

Loader.sendToClient = {
    open: function(option) {
        Loader.changeTitle("Opening Client Information...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    saving: function(option) {
        Loader.changeTitle("Saving Client Information...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};

Loader.shareViaEmail = {
    open: function(option) {
        Loader.changeTitle("Opening Share Via Email...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    sharing: function(option) {
        Loader.changeTitle("Sharing Design Via Email...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};

Loader.logs = {
    open: function(option) {
        Loader.changeTitle("Opening Change Logs...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    fixing: function(option) {
        Loader.changeTitle("Fixing Change Logs...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};

Loader.redirect = {
    customizer: function(option) {
        Loader.MAX_LOADER_VALUE = 80;
        Loader.changeTitle("Opening Customizer...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    item: function(option) {
        Loader.changeTitle("Redirecting To Item Page...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    hub: function(option) {
        Loader.changeTitle("Redirecting To Hub...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    style: function(option) {
        Loader.changeTitle("Redirecting To Picker...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    savedCart: function(option) {
        Loader.changeTitle("Redirecting To Saved Cart Page...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },
};

Loader.cart = {
    saveAndExit: function(option) {
        Loader.changeTitle("Save And Exit...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    open: function(option) {
        Loader.changeTitle("Opening Cart Information...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    create: function(option) {
        Loader.changeTitle("Creating New Cart...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    updateCartTitle: function(option) {
        Loader.changeTitle("Updating Cart Info...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    updateItemName: function(option) {
        Loader.changeTitle("Updating Item Name...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};

Loader.coach = {
    approving: function(option) {
        Loader.changeTitle("Approving Item...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    changesRequest: function(option) {
        Loader.changeTitle("Sending request...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};

Loader.orderForm = {
    submitOrder: function(option) {
        Loader.changeTitle("Submitting orders...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};


Loader.orderItem = {
    show: function(option) {
        Loader.changeTitle("Viewing Order Item");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    showNotes: function(option) {
        Loader.changeTitle("Showing order notes");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    preview: function(option) {
        Loader.changeTitle("Showing design preview");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    updating: function(option) {
        Loader.changeTitle("Updating Design");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
};

Loader.uploadRoster = {
    uploading: function(option) {
        Loader.changeTitle("Uploading Roster");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
}

Loader.forApproval = {
    showing: function(option) {
        Loader.changeTitle("Showing items");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    sending: function(option) {
        Loader.changeTitle("Sending...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    resend: function(option) {
        Loader.changeTitle("Resending...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
}

Loader.saveForLater = {
    loading: function(option) {
        Loader.changeTitle("Loading items..");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    saving: function(option) {
        Loader.changeTitle("Saving item for later..");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    deleting: function(option) {
        Loader.changeTitle("Deleting item..");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    moving: function(option) {
        Loader.changeTitle("Moving item..");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    showing: function(option) {
        Loader.changeTitle("Loading cart list..");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    },

    checking: function(option) {
        Loader.changeTitle("Loading...");
        Loader.changeLoaderValue(1);
        Loader.show(option);
    }
}

